import React, { useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation, Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import LogoLockerimRavBariah from "assets/images/logo_lockerim_rav_bariah.png";

import { useStorage } from "hooks/useStorage";
import useTranslations from "hooks/useTranslations";

import SideMenu from "components/SideMenu/SideMenu";
import Button from "components/Button/Button";

import { ReactComponent as BurgerLogo } from "assets/images/burger.svg";

import "./Header.scss";

const Header = ({ showGoBackButton = true, IS_MIGRATION = false }) => {
  const [showSideMenu, setShowSideMenu] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { removeLocalStorageData } = useStorage();
  const { rcTranslate } = useTranslations();

  const homepageLinks = [
    {
      title: "ראשי",
      link: "/"
    },
    {
      title: "איך זה עובד",
      link: "/how-it-works"
    },
    {
      title: "שאלות ותשובות",
      link: "/faq"
    }
  ];

  const showHomepageContent = useMemo(
    () => !location?.pathname?.includes("/onboarding"),
    [location?.pathname]
  );

  const goToMainPage = () => {
    removeLocalStorageData();
    history.push("/");
  };

  const goToLandingPage = () => {
    removeLocalStorageData();
    history.push("/onboarding/landing-page");
  };

  return ReactDOM.createPortal(
    <div className={"header__wrapper"}>
      {showGoBackButton && !showHomepageContent && (
        <button className="header__link" role={"link"} onClick={goToMainPage}>
          {rcTranslate("button.backToMainPage")}
        </button>
      )}

      {showGoBackButton && showHomepageContent && (
        <div className="header__homepageLeftSide">
          <IconButton
            className="header__burger"
            onClick={() => setShowSideMenu(true)}
          >
            <BurgerLogo />
          </IconButton>

          <Button
            className="header__orderLockerButton"
            title={rcTranslate("button.orderLocker")}
            onClick={goToLandingPage}
            color="white"
          />
        </div>
      )}

      {showHomepageContent && (
        <div className="header__links" style={IS_MIGRATION ? {visibility: 'hidden'} : {}}>
          {homepageLinks.map((item, index) => (
            <Link key={index} className="header__link" to={item.link}>
              {item.title}
            </Link>
          ))}
        </div>
      )}

      {showHomepageContent && (
        <SideMenu
          items={homepageLinks}
          show={showSideMenu}
          onClose={() => setShowSideMenu(false)}
        />
      )}

      <button className="header__logo-button">
          <img src={LogoLockerimRavBariah} alt="logoRavBariah" className="logoImage" onClick={goToMainPage}/>
      </button>
    </div>,
    document.getElementById("header")
  );
};

export default Header;
