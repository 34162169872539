import axios from "axios";
import store from '../store/store';
import { ErrorActions, ErrorPriorityEnum, ErrorTypeEnum } from "store/errors/errorsActions";
import ErrorCodes from "utils/constants/errorCodes";


class Api {
    axiosInstance;

    constructor(){
        this.axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 1 * 60 * 1000
        });
        this.responseInteceptor();
    }

    get = (url) => this.axiosInstance.get(url);

    post = (url, data) => this.axiosInstance.post(url, data);

    put = (url, data) => this.axiosInstance.put(url, data);

    responseInteceptor = () => {
        this.axiosInstance.interceptors.response.use(
          async value => {
            if(!value?.data?.isSucceeded){
                const errorMessage = value?.data?.errors[0]?.message || null;
                const errorCode = value?.data?.errors[0]?.code || ErrorCodes.GeneralError;
                
                store.dispatch(ErrorActions.pushError({
                    code: errorCode,
                    message: errorMessage,
                    priority: ErrorPriorityEnum.HIGH,
                    type: ErrorTypeEnum.SERVER,
                  }));
            }
            return value;
          },
          async error => {
            store.dispatch(ErrorActions.pushError({
                code: ErrorCodes.GeneralError,
                priority: ErrorPriorityEnum.HIGH,
                type: ErrorTypeEnum.SERVER,
              }));
            throw Error(error)
          },
        );
      };
}

export const ApiService = new Api();